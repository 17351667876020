<template>
  <div class="register2">
    <Navbar />
    <div>
      <section class="pt-5 mb-5">
        <div class="container">
          <HeadSection txt="คำร้องขอแก้ผลการเรียน" />
          <form
            @change="updateMessage"
            @submit.prevent="pushMessage"
            v-show="!status"
          >
            <div class="row justify-content-center">
              <div class="col-sm-2 text-center text-md-end">ประเภทคำร้อง</div>
              <div class="col-sm-4 mb-2">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    v-model="form.type"
                    value="แก้ 0"
                  />
                  <label class="form-check-label" for="inlineRadio1"
                    >แก้ 0</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    v-model="form.type"
                    value="แก้ ร"
                  />
                  <label class="form-check-label" for="inlineRadio2"
                    >แก้ ร</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    v-model="form.type"
                    value="แก้ มผ"
                  />
                  <label class="form-check-label" for="inlineRadio2"
                    >แก้ มผ</label
                  >
                </div>
              </div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-2 mb-2">
                    <div class="form-floating">
                      <select
                        class="form-select"
                        id="floatingSelect"
                        v-model="form.prename"
                      >
                        <option v-for="i in pre" :key="i" :value="i">
                          {{ i }}
                        </option>
                      </select>
                      <label for="floatingSelect">คำนำหน้า</label>
                    </div>
                  </div>
                  <div class="col-md-5 mb-2">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingName"
                        v-model="form.fname"
                        required
                      />
                      <label for="floatingName">ชื่อ</label>
                    </div>
                  </div>
                  <div class="col-md-5 mb-2">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingLastname"
                        v-model="form.lname"
                        required
                      />
                      <label for="floatingLastname">นามสกุล</label>
                    </div>
                  </div>
                  <div class="col-md-2 mb-2">
                    <div class="form-floating">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingLevel"
                        v-model="form.level"
                        min="1"
                        max="6"
                        required
                      />
                      <label for="floatingLevel">ชั้นมัธยมศึกษาปีที่</label>
                    </div>
                  </div>
                  <div class="col-md-2 mb-2">
                    <div class="form-floating">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingSubLevel"
                        v-model="form.sub_level"
                        min="1"
                        max="20"
                      />
                      <label for="floatingSubLevel">ห้อง</label>
                    </div>
                  </div>
                  <div class="col-md-3 mb-2">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingSubject"
                        v-model="form.subject_code"
                        required
                      />
                      <label for="floatingSubject">รหัสวิชา</label>
                    </div>
                  </div>
                  <div class="col-md-5 mb-2">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingSubjectName"
                        v-model="form.subject"
                        required
                      />
                      <label for="floatingSubjectName">ชื่อวิชา</label>
                    </div>
                  </div>
                  <div class="w-100"><hr /></div>
                  <div class="col-sm-2">ประเภทวิชา</div>
                  <div class="col-sm-4 mb-2 text-start">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioSubjectType"
                        v-model="form.subject_type"
                        value="พื้นฐาน"
                      />
                      <label class="form-check-label" for="inlineRadio1"
                        >พื้นฐาน</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioSubjectType"
                        v-model="form.subject_type"
                        value="เพิ่มเติม"
                      />
                      <label class="form-check-label" for="inlineRadio2"
                        >เพิ่มเติม</label
                      >
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-md-2 mb-2">
                    <div class="form-floating">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingTerm"
                        v-model="form.term"
                        min="1"
                        max="2"
                        required
                      />
                      <label for="floatingTerm">ภาคเรียนที่</label>
                    </div>
                  </div>
                  <div class="col-md-2 mb-2">
                    <div class="form-floating">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingYear"
                        v-model="form.year"
                        min="1"
                        required
                      />
                      <label for="floatingYear">ปีการศึกษา</label>
                    </div>
                  </div>
                  <div class="col-md-8 mb-2">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingTeacherName"
                        v-model="form.teacher"
                      />
                      <label for="floatingTeacherName">ครูประจำวิชา</label>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col text-start">
                    <button
                      type="submit"
                      name="submit_btn"
                      class="btn btn-primary"
                    >
                      ส่งข้อมูล
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <Sent url="register2" :param="param" v-show="status" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import HeadSection from "@/components/HeadSection.vue";
import Sent from "@/components/Sent.vue";

import firebase from "../db.js";
import "firebase/database";

const dbRef = firebase.database().ref("documents");

export default {
  components: {
    Navbar,
    HeadSection,
    Sent,
  },
  data: function () {
    return {
      form: {
        type: "แก้ 0",
        prename: "เด็กชาย",
        fname: "",
        lname: "",
        subject_code: "",
        subject: "",
        subject_type: "พื้นฐาน",
        term: "",
        year: "",
        teacher: "",
        timestamp: new Date().toUTCString(),
        name: "",
        status: "PENDING",
      },
      status: false,
      pre: ["เด็กชาย", "เด็กหญิง", "นาย", "นาง", "นางสาว"],
      param: "",
    };
  },

  methods: {
    updateMessage() {
      this.form.name = this.name;
      this.$store.state.form = this.form;
    },

    async pushMessage(e) {
      e.submitter.innerText = "กรุณารอซักครู่";
      e.submitter.disabled = true;

      let last = 0;

      await dbRef
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            last = snapshot.numChildren();
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });

      let count = 0;
      if (last > 0) {
        count = last;
      }
      await dbRef
        .child(count)
        .set(this.form)
        .then(() => {
          this.status = true;
          this.param = count;
        })
        .catch(() => {
          alert("ไม่สามารถส่งข้อมูลได้");
        });
    },
  },
  computed: {
    name: function () {
      let txt = this.form.prename + this.form.fname + "  " + this.form.lname;
      return txt;
    },
  },
};
</script>

<style>
</style>